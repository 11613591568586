<template>
    <columns v-show="!itemCount">
        <column class="has-text-centered p-6">
            <p class="title is-5">
                {{ heading }}
            </p>
            <router-link v-if="withLink" class="button is-rounded" :to="to">
                <span>{{ toText }}</span>
                <icon icon="arrow-right"/>
            </router-link>
        </column>
    </columns>
</template>
<script>
export default {
    
    props: {
        itemCount: Number,
        heading: String,
        withLink: {
            type: Boolean,
            default: false
        },
        to: {
            type: Object,
            default: () => ({})
        },
        toText: String
    }

}
</script>